.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0e0e0e;
    color: white;
    text-align: center;
  }
  
  .title {
    font-size: 15rem;
    margin-bottom: 1rem;
   
    color: transparent; /* Hide the default text color */
    animation: gradientAnimation 5s linear infinite;
    background: linear-gradient(60deg, #292929, #ebeaea, #292929);
    background-clip: text;
    -webkit-background-clip: text; /* Needed for WebKit browsers */
  }
  
  
  .typing-text {
    font-size: 4rem;
    min-height: 4rem;
    margin-bottom: 1rem;
    color: transparent;
    background: linear-gradient(20deg, #f56c03, #ff0000, #000000);
    background-clip: text;
    -webkit-background-clip: text;
  }
  
  .cursor {
    display: inline-block;
    width: 10px;
    background-color: rgb(235, 0, 0);
  }
  
  .blink {
    opacity: 0;
  }  

  .launching-soon {
    font-size: 5.5rem;
    margin: 2rem 0;
    animation: gradientAnimation 5s linear infinite;
    background: linear-gradient(50deg, #292929, #ff4000, #292929);
    background-clip: text;
    -webkit-background-clip: text; /* Needed for WebKit browsers */
    color: transparent; /* Hide the default text color */

  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 30% 30%;
    }
  }

  .discord-join a {
    color: inherit; /* Inherit color from parent */
    text-decoration: none; /* Remove underline */
  }

  .discord-join {
    font-size: 2rem;
    margin-top: 2rem;
    padding: 10px 20px;
    border: 2px solid #580000;
    color: #ffffff;
    
    animation: neonGlow 1.5s ease-in-out infinite alternate;
    text-decoration: none;
    transition: transform 0.3s ease;
  }

  .discord-join:hover {
    transform: scale(1.1);
  }
  